import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import {Toast} from "../../toast/TokyoToaster";
import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import {CommonUtil} from "../../../util/CommonUtil";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import {QISwitch} from "../../switch/QISwitch";
import WindowUtil from "../../../util/WindowUtil";

import "./ReflectionSettingsModal.css";

export const NotificationPreferenceSettingsModal = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [forceDismiss, setForceDismiss] = useState(false);

    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const [preferences, setPreferences] = useState(null);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
            fetchNotificationPreferencesFromNetwork();
        } else {
            WindowUtil.unlockBodyScroll();
            setForceDismiss(false);
        }
    }, [shown]);

    useEffect(() => {
        if (preferences && preferences.enabled === 1) {
            const newPrefs = CommonUtil.cloneObject(preferences);

            let shouldUpdate = false;
            ["admitEd", "ctScan", "highRisk", "assessRCS", "sepsisIdent", "elderCR"].forEach((key) => {
                if (newPrefs.hasOwnProperty(key)) {
                    if (parseInt(newPrefs[key]) === 0) {
                        newPrefs[key] = 1;
                        shouldUpdate = true;
                    }
                } else {
                    newPrefs[key] = 1;
                    shouldUpdate = true;
                }
            });

            if (shouldUpdate) {
                setPreferences(newPrefs);
            }
        }
    }, [preferences]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function setPreference(keyName, value) {
        const preferenceData = CommonUtil.cloneObject(preferences);
        preferenceData[keyName] = value;
        setPreferences(preferenceData);
    }

    function getPreference(keyName, defaultValue) {
        if (preferences && preferences.hasOwnProperty(keyName)) {
            if (preferences[keyName]) {
                return preferences[keyName];
            }
        }
        return defaultValue;
    }

    function fetchNotificationPreferencesFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        Axios.post(ENDPOINTS.user.getUserNotificationPreferences, {})
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setPreferences(resp.data);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("UNP1000C"));
            });
    }

    function submitNotificationPreferencesOverNetwork() {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        const postData = new FormData();
        postData.append("data", JSON.stringify(preferences));

        Axios.post(ENDPOINTS.user.submitUserNotificationPreferences, postData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("settings.notification_preferences_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("UNP2000C"));
            });
    }

    // RENDER

    if (!shown) return [];

    let mainContent = [];

    if (!networkInFlight) {
        mainContent.push(
            <ul className={"list-group"}>
                <li className={"list-group-item setting-option"}>
                    <div className={"preference-switch"}>
                        <QISwitch
                            value={parseInt(getPreference("enabled", 0)) === 1}
                            onChange={(value) => setPreference("enabled", value ? 1 : 0)}/>
                    </div>
                    <div className={"preference-content"}>
                        <div className={"flex-fill"}>
                            {Rosetta.string("registration.notifications_option_overall")}
                        </div>
                    </div>
                </li>
            </ul>
        );

        if (parseInt(getPreference("enabled", 0)) === 0) {
            mainContent.push(
                <ul className={"list-group mt-4"}>
                    <li className={"list-group-item setting-option"}>
                        <strong>{Rosetta.string("registration.notifications_heading_case_reviews")}</strong>
                    </li>

                    <li className={"list-group-item setting-option"}>
                        <div className={"preference-switch"}>
                            <QISwitch
                                value={parseInt(getPreference("admitEd", 0)) === 1}
                                onChange={(value) => setPreference("admitEd", value ? 1 : 0)}/>
                        </div>
                        <div className={"preference-content"}>
                            <div className={"flex-fill"}>
                                {Rosetta.string("registration.notifications_option_admit_via_ed")}
                            </div>
                        </div>
                    </li>

                    <li className={"list-group-item setting-option"}>
                        <div className={"preference-switch"}>
                            <QISwitch
                                value={parseInt(getPreference("ctScan", 0)) === 1}
                                onChange={(value) => setPreference("ctScan", value ? 1 : 0)}/>
                        </div>
                        <div className={"preference-content"}>
                            <div className={"flex-fill"}>
                                {Rosetta.string("registration.notifications_option_ct_scan")}
                            </div>
                        </div>
                    </li>

                    <li className={"list-group-item setting-option"}>
                        <div className={"preference-switch"}>
                            <QISwitch
                                value={parseInt(getPreference("highRisk", 0)) === 1}
                                onChange={(value) => setPreference("highRisk", value ? 1 : 0)}/>
                        </div>
                        <div className={"preference-content"}>
                            <div className={"flex-fill"}>
                                {Rosetta.string("registration.notifications_option_high_risk")}
                            </div>
                        </div>
                    </li>

                    <li className={"list-group-item setting-option"}>
                        <div className={"preference-switch"}>
                            <QISwitch
                                value={parseInt(getPreference("assessRCS", 0)) === 1}
                                onChange={(value) => setPreference("assessRCS", value ? 1 : 0)}/>
                        </div>
                        <div className={"preference-content"}>
                            <div className={"flex-fill"}>
                                {Rosetta.string("registration.notifications_option_assessed_rcs")}
                            </div>
                        </div>
                    </li>

                    <li className={"list-group-item setting-option"}>
                        <div className={"preference-switch"}>
                            <QISwitch
                                value={parseInt(getPreference("sepsisIdent", 0)) === 1}
                                onChange={(value) => setPreference("sepsisIdent", value ? 1 : 0)}/>
                        </div>
                        <div className={"preference-content"}>
                            <div className={"flex-fill"}>
                                {Rosetta.string("registration.notifications_option_sepsis_identified")}
                            </div>
                        </div>
                    </li>

                    <li className={"list-group-item setting-option"}>
                        <div className={"preference-switch"}>
                            <QISwitch
                                value={parseInt(getPreference("elderCR", 0)) === 1}
                                onChange={(value) => setPreference("elderCR", value ? 1 : 0)}/>
                        </div>
                        <div className={"preference-content"}>
                            <div className={"flex-fill"}>
                                {Rosetta.string("registration.notifications_option_elderly_care_review")}
                            </div>
                        </div>
                    </li>
                </ul>
            );

            // TODO 2024-11-26 Disabled by client request.
            // mainContent.push(
            //     <ul className={"list-group mt-4"}>
            //         <li className={"list-group-item"}>
            //             <label>{Rosetta.string("registration.notifications_option_surgeon_gmc_number")}</label>
            //             <input
            //                 type={"text"}
            //                 className={"form-control"}
            //                 value={getPreference("surgeonGMC")}
            //                 onChange={(e) => setPreference("surgeonGMC", e.target.value)}
            //             />
            //         </li>
            //
            //         <li className={"list-group-item"}>
            //             <label>{Rosetta.string("registration.notifications_option_anaesthetist_gmc_number")}</label>
            //             <input
            //                 type={"text"}
            //                 className={"form-control"}
            //                 value={getPreference("anaesthetistGMC")}
            //                 onChange={(e) => setPreference("anaesthetistGMC", e.target.value)}
            //             />
            //         </li>
            //     </ul>
            // );
        }

        let submitButton = (
            <button
                className={"btn btn-primary full-width"}
                onClick={() => submitNotificationPreferencesOverNetwork()}>
                {Rosetta.string("common.save")}
            </button>
        );

        if (submitNetworkInFlight) {
            submitButton = (
                <button className={"btn btn-primary full-width disabled"}>
                    {Rosetta.string("common.save")}
                </button>
            );
        }

        mainContent.push(
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    {submitButton}
                </div>
            </div>
        )
    } else {
        mainContent = (
            <ul className={"list-group"}>
                {[0, 0, 0, 0].map(() => (
                    <li><PlaceholderText/></li>
                ))}
            </ul>
        )
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("settings.notification_preferences_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    {Rosetta.string("settings.notification_preferences_explain")}
                </div>
            </div>

            <div className={"row mt-4 reflection-settings-modal"}>
                <div className={"col-12"}>
                    {mainContent}
                </div>
            </div>

        </Offcanvas>
    )
}