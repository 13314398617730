import {ScreenTitle} from "../../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";
import {
    Button,
    Check,
    CheckList,
    Container,
    DateTime,
    DropDown,
    Former,
    FormerActions,
    Text
} from "../../../form/Former";
import {useEffect, useRef, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {PasswordValidator, PasswordValidatorActions} from "../../../password/PasswordValidator";

import "./RegistrationScreen.css";

import iconDocument from "../../../../assets/images/icon_document.svg";
import {ImageUtil} from "../../../../util/ImageUtil";
import {Chronos} from "../../../../chronos/Chronos";
import Validator from "../../../../util/Validator";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {Navigator} from "../../../../util/Navigator";
import {OrganisationPickerModal} from "../../organisation/OrganisationPickerModal";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";
import ArrayUtil from "../../../../util/ArrayUtil";
import {CommonUtil} from "../../../../util/CommonUtil";

export const RegistrationScreen = (props) => {

    const notificationCategories = ["admit_ed","ct_scan","high_risk","assess_rcs","sepsis_ident","elder_cr"];

    const [formData, setFormData] = useState({});

    const [acceptedDomains, setAcceptedDomains] = useState(["explodingphone.com", "nhs.net"]);
    const [domainNetworkInFlight, setDomainNetworkInFlight] = useState(false);

    const [organisationId, setOrganisationId] = useState(undefined);
    const [organisation, setOrganisation] = useState(undefined);
    const [showOrganisationPicker, setShowOrganisationPicker] = useState(true);

    const [clinicalRoles, setClinicalRoles] = useState([]);
    const [roleNetworkInFlight, setRoleNetworkInFlight] = useState(false);

    const [policies, setPolicies] = useState([]);
    const [policyNetworkInFlight, setPolicyNetworkInFlight] = useState(false);
    const [policiesAccepted, setPoliciesAccepted] = useState(false);

    const [passwordValidated, setPasswordValidated] = useState(false);
    const [password, setPassword] = useState(null);
    const [passwordRetype, setPasswordRetype] = useState(null);
    const [passwordMatch, setPasswordMatch] = useState(true);

    const [emailVerified, setEmailVerified] = useState(undefined);
    const emailVerifyTimeout = useRef();

    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const [organisationPickerShown, setOrganisationPickerShown] = useState(false);

    const [showGCMNumber, setShowGCMNumber] = useState(false);

    const [showNotificationOptions, setShowNotificationOptions] = useState(false);
    const [showSurgeonGMC, setShowSurgeonGMC] = useState(false);
    const [showAnaesthetistsGMC, setShowAnaesthetistsGMC] = useState(false);

    const ageBands = [
        Rosetta.string("registration.user_age_band_lt30"),
        Rosetta.string("registration.user_age_band_30_40"),
        Rosetta.string("registration.user_age_band_40_50"),
        Rosetta.string("registration.user_age_band_50_60"),
        Rosetta.string("registration.user_age_band_gt60")
    ];

    const responsibilities = [
        Rosetta.string("registration.user_responsibility_surgery"),
        Rosetta.string("registration.user_responsibility_anaesthetics"),
        Rosetta.string("registration.user_responsibility_ed"),
        Rosetta.string("registration.user_responsibility_radiology"),
        Rosetta.string("registration.user_responsibility_critical_care"),
        Rosetta.string("registration.user_responsibility_cote"),
        Rosetta.string("registration.user_responsibility_emergency_theatre"),
        Rosetta.string("registration.user_responsibility_other"),
        Rosetta.string("registration.user_responsibility_nonclinical")
    ];

    useEffect(() => {
        initForm();

        fetchClinicalRolesFromNetwork();
        fetchPoliciesFromNetwork();

        // TODO Work out organisation ID if able
    }, []);

    useEffect(() => {
        if (password && passwordRetype) {
            setPasswordMatch((password === passwordRetype) || (password === "" && passwordRetype === ""));
        }
    }, [password, passwordRetype]);

    useEffect(() => {
        fetchPoliciesFromNetwork();
        fetchAcceptedDomainsFromNetwork();
    }, [organisationId]);

    function initForm() {
        const newFormData = CommonUtil.cloneObject(formData);
        newFormData.notification_overall = "1";
        newFormData.notification_patients = notificationCategories;

        setFormData(newFormData);
    }

    function formDidCallback(action, data) {
        if (action === FormerActions.CHANGE) {
            // Update form state
            const newFormData = CommonUtil.cloneObject(formData);
            newFormData[data.name] = data.value;
            setFormData(newFormData);

            // Look for specific values
            if (data.name === "emailAddress") {
                if (data.value === "") {
                    setEmailVerified(undefined);
                } else {
                    clearTimeout(emailVerifyTimeout.current);
                    emailVerifyTimeout.current = setTimeout(() => {
                        setEmailVerified(validateEmailAddress(data.value));
                    }, 2000);
                }
            } else if (data.name === "passwordRetype") {
                setPasswordRetype(data.value);
            } else if (data.name === "clinicalRoleId") {
                const clinicalRoleId = parseInt(data.value);
                let clinicalRole = null;

                for (let role of clinicalRoles) {
                    console.log(role);
                    if (parseInt(role.id) === clinicalRoleId) {
                        clinicalRole = role;
                        break;
                    }
                }

                let showGCM = false;
                if (clinicalRole !== null) {
                    console.log("FOUND ROLE: ", clinicalRole);
                    showGCM = clinicalRole.name.includes("Doctor -");
                }
                console.log("Show GCM?", showGCM);
                setShowGCMNumber(showGCM);
            } else if (data.name === "notification_overall") {
                const overallValue = parseInt(data.value) === 1

                if (overallValue) {
                    // When "all" is selected, set all categories to checked.
                    const newFormData = CommonUtil.cloneObject(formData);
                    newFormData.notification_overall = "1";
                    newFormData.notification_patients = notificationCategories;
                    setFormData(newFormData);
                }

                setShowNotificationOptions(!overallValue);
            } else if (data.name === "notification_surgeon") {
                setShowSurgeonGMC(parseInt(data.value) === 1);
            } else if (data.name === "notification_anaesthetist") {
                setShowAnaesthetistsGMC(parseInt(data.value) === 1);
            }
        } else if (action === FormerActions.SUBMIT) {
            if (data.success) {
                submitRegistrationOverNetwork(data.data);
            }
        }
    }

    function passwordDidCallback(action, data) {
        if (action === PasswordValidatorActions.CHANGE) {
            setPasswordValidated(data.validated);
            setPassword(data.password);
        }
    }

    function validateEmailAddress(emailAddress) {
        const emailSplit = emailAddress.split("@");
        if (emailSplit.length > 0) {
            let hostPart = emailSplit[emailSplit.length - 1].toLowerCase();

            for (let i = 0; i < acceptedDomains.length; i++) {
                if (hostPart === acceptedDomains[i]) {
                    return true;
                }
            }
        }
        return false;
    }

    function handlePolicyClick(policy) {
        if (policy) {
            if (policy.url !== null) {
                window.open(policy.url);
            } else if (policy.filePath !== null) {
                window.open(policy.filePath);
            } else {
                AlertModal.showModal(
                    policy.title,
                    policy.description,
                    [
                        AlertModal.button(
                            Rosetta.string("common.close"),
                            () => {
                                AlertModal.dismissModal();
                            }
                        )
                    ]
                );
            }
        }
    }

    function summonOrganisationPicker() {
        setOrganisationPickerShown(true);
    }

    function organisationPickerDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setOrganisationId(data.id);
                setOrganisation(data);
            }

            setOrganisationPickerShown(false);
        }
    }

    function launchPage(page) {
        window.open(process.env.REACT_APP_BASE_URL + page);
    }

    function fetchClinicalRolesFromNetwork() {
        if (roleNetworkInFlight) return;
        setRoleNetworkInFlight(true);

        const formData = new FormData();
        formData.append("registrationOnly", "true");

        Axios.post(ENDPOINTS.auth.getClinicalRoles, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setClinicalRoles(resp.data.clinicalRoles);
                } else {
                    console.log(API.formatError(resp));
                }
                setRoleNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setRoleNetworkInFlight(false);
            });
    }

    function fetchPoliciesFromNetwork() {
        if (policyNetworkInFlight || !organisationId) return;
        setPolicyNetworkInFlight(true);

        const formData = new FormData();
        formData.append("organisationId", organisationId);
        formData.append("requiresAcceptance", 1);

        Axios.post(ENDPOINTS.organisation.getOrganisationPolicies, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setPolicies(resp.data.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setPolicyNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setPolicyNetworkInFlight(false);
            })
    }

    function submitRegistrationOverNetwork(inData) {
        if (submitNetworkInFlight) return;

        const formState = CommonUtil.cloneObject(inData);

        formState.organisationId = organisationId;
        formState.password = password;

        if (formState.expectedEndDate) {
            const endDateChronos = Chronos.parse(formState.expectedEndDate);
            formState.expectedEndDate = endDateChronos.seconds();
        }

        const validationResult = Validator.validateCreateFormData(formState, [
            Validator.rule("organisationId", "int", "", "organisationId", true),
            Validator.rule("emailAddress", "string", Rosetta.string("registration.user_email"), "emailAddress", false),
            Validator.rule("password", "string", Rosetta.string("registration.user_password"), "password", false),
            Validator.rule("givenName", "string", Rosetta.string("registration.user_given_name"), "givenName", false),
            //Validator.rule("middleName", "string", Rosetta.string("registration.user_middle_names"), "middleName", true),
            Validator.rule("familyName", "string", Rosetta.string("registration.user_family_name"), "familyName", false),
            Validator.rule("ageBand", "string", Rosetta.string("registration.user_age_band"), "ageBand", true),
            Validator.rule("expectedEndDate", "int", Rosetta.string("registration.user_expected_date"), "endDate", true),
            Validator.rule("gcmNumber", "string", Rosetta.string("registration.user_gcm_number"), "gcmNumber", true),
            Validator.rule("responsibility", "string", Rosetta.string("registration.user_responsibility"), "responsibility", true),
            Validator.rule("clinicalRoleId", "string", Rosetta.string("registration.user_clinical_role"), "clinicalRoleId", true),
            Validator.rule("notification_overall", "int", Rosetta.string("registration.user_notification_overall"), "notification_overall", false),
            Validator.rule("notification_patients", "string", "", "notification_patients", true),
            Validator.rule("notification_surgeon", "int", "", "notification_surgeon", true),
            Validator.rule("notification_surgeon_gmc", "string", "", "notification_surgeon_gmc", true),
            Validator.rule("notification_anaesthetist", "int", "", "notification_anaesthetist", true),
            Validator.rule("notification_anaesthetist_gmc", "string", "", "notification_anaesthetist_gmc", true)
        ]);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        setSubmitNetworkInFlight(true);

        const formData = validationResult.formData;

        Axios.post(ENDPOINTS.auth.submitUserRegistration, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    AlertModal.showModal(
                        Rosetta.string("registration.submission_success_title"),
                        Rosetta.string("registration.submission_success_message"),
                        [
                            AlertModal.button(
                                Rosetta.string("registration.submission_success_finish"),
                                () => {
                                    Navigator.navigate("/");

                                    AlertModal.dismissModal();
                                }
                            )
                        ]
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("URS1000C"));
            });
    }

    function fetchAcceptedDomainsFromNetwork() {
        if (domainNetworkInFlight) return;
        setDomainNetworkInFlight(true);

        const formData = new FormData();
        formData.append("organisationId", organisationId);

        Axios.post(ENDPOINTS.organisation.getOrganisationAcceptedDomains, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let newDomains = ArrayUtil.appendArray([
                        "explodingphone.com",
                        "nhs.net"
                    ], resp.data.domains, true);

                    setAcceptedDomains(newDomains);
                } else {
                    console.log(API.formatError(resp));
                }
                setDomainNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDomainNetworkInFlight(false);
            })
    }

    // RENDER

    let allowSubmit = passwordValidated && organisationId;

    let organisationPickElem = [];
    if (showOrganisationPicker) {
        let orgLabel = Rosetta.string("registration.organisation_pick_none");
        if (organisation) {
            orgLabel = organisation.name;
        }

        organisationPickElem = (
            <div className={"row"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("registration.organisation_pick")}</label>
                    <div className={"form-control clickable"} onClick={() => summonOrganisationPicker()}>
                        {orgLabel}
                    </div>
                </div>

            </div>
        )
    }

    let emailInvalidElem = [];
    if (emailVerified === false) {
        allowSubmit = false;

        emailInvalidElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>
                        {Rosetta.string("registration.user_email_invalid")}
                    </div>
                </div>
            </div>
        )
    }

    let passwordMismatchElem = [];
    if (!passwordMatch) {
        allowSubmit = false;

        passwordMismatchElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>
                        {Rosetta.string("registration.user_password_retry_violation")}
                    </div>
                </div>
            </div>
        )
    }

    let policyElems = [];
    if (policies.length > 0) {
        allowSubmit = allowSubmit && policiesAccepted;

        policies.forEach((policy) => {
            let lastUpdate = "";
            if (policy.dateUpdated !== null) {
                lastUpdate = Chronos.withTimestampSeconds(policy.dateUpdated).format("dd/MM/yyyy");
            } else {
                lastUpdate = Chronos.withTimestampSeconds(policy.dateCreated).format("dd/MM/yyyy");
            }

           policyElems.push(
               <li className={"list-group-item policy-document-item clickable"} onClick={() => handlePolicyClick(policy)}>
                   <div className={"icon"} style={{backgroundImage : ImageUtil.background(iconDocument)}} />
                   <div className={"content"}>
                       <div className={"document-title"}>{policy.title}</div>
                       <div><small>{Rosetta.string("registration.policy_date", { date : lastUpdate })}</small></div>
                   </div>
               </li>
           );
        });

        if (policyElems.length > 0) {
            policyElems = (
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <h3>{Rosetta.string("registration.policy_title")}</h3>
                    </div>

                    <div className={"col-12 mt-2"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <strong>{Rosetta.string("registration.policy_agreement_message")}</strong>
                            </div>

                            <div className={"card-body"}>
                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <ul className={"list-group"}>
                                            {policyElems}
                                        </ul>
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <label><input type={"checkbox"} value={policiesAccepted} onChange={(e) => setPoliciesAccepted(e.target.checked)} /> {Rosetta.string("registration.policy_agreement_title")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    let submitButton = <Button className={"btn btn-success"} label={Rosetta.string("common.submit")} />;
    if (!allowSubmit) {
        submitButton = <span className={"btn btn-success disabled"}>{Rosetta.string("common.submit")}</span>;
    }

    let gcmNumberElem = [];
    if (showGCMNumber) {
        gcmNumberElem = (
            <Container className={"row mt-2"}>
                <Container className={"col-12"}>
                    <Text
                        name={"gcmNumber"}
                        label={Rosetta.string("registration.user_gcm_number")}
                        className={"form-control"}
                        mandatory={false} />
                </Container>
            </Container>
        );
    }

    let notificationFields = [];
    if (showNotificationOptions) {
        notificationFields.push(
            <Container className={"row mt-2"}>
                <Container className={"col-12"}>
                    <CheckList
                        name={"notification_patients"}
                        label={Rosetta.string("registration.notifications_heading_case_reviews")}
                        className={"form-select"}>

                        <Check value={"admit_ed"}>{Rosetta.string("registration.notifications_option_admit_via_ed")}</Check>
                        <Check value={"ct_scan"}>{Rosetta.string("registration.notifications_option_ct_scan")}</Check>
                        <Check value={"high_risk"}>{Rosetta.string("registration.notifications_option_high_risk")}</Check>
                        <Check value={"assess_rcs"}>{Rosetta.string("registration.notifications_option_assessed_rcs")}</Check>
                        <Check value={"sepsis_ident"}>{Rosetta.string("registration.notifications_option_sepsis_identified")}</Check>
                        <Check value={"elder_cr"}>{Rosetta.string("registration.notifications_option_elderly_care_review")}</Check>

                    </CheckList>
                </Container>
            </Container>
        );

        // TODO Disabled at client request - CADE 2024-11-26
        // notificationFields.push(
        //     <Container className={"row mt-2"}>
        //         <Container className={"col-12"}>
        //             <DropDown
        //                 name={"notification_surgeon"}
        //                 label={Rosetta.string("registration.notifications_option_consultant_surgeons")}
        //                 className={"form-select"}>
        //
        //                 <option value={"1"}>{Rosetta.string("common.yes")}</option>
        //                 <option value={"0"}>{Rosetta.string("common.no")}</option>
        //
        //             </DropDown>
        //         </Container>
        //     </Container>
        // );
        //
        // if (showSurgeonGMC) {
        //     notificationFields.push(
        //         <Container className={"row mt-2"}>
        //             <Container className={"col-12"}>
        //                 <Text
        //                     name={"notification_surgeon_gmc"}
        //                     label={Rosetta.string("registration.notifications_option_surgeon_gmc_number")}
        //                     className={"form-control"}
        //                     mandatory={false}/>
        //             </Container>
        //         </Container>
        //     );
        // }
        //
        // notificationFields.push(
        //     <Container className={"row mt-2"}>
        //         <Container className={"col-12"}>
        //             <DropDown
        //                 name={"notification_anaesthetist"}
        //                 label={Rosetta.string("registration.notifications_option_consultant_anaesthetists")}
        //                 className={"form-select"}>
        //
        //                 <option value={"1"}>{Rosetta.string("common.yes")}</option>
        //                 <option value={"0"}>{Rosetta.string("common.no")}</option>
        //
        //             </DropDown>
        //         </Container>
        //     </Container>
        // );
        //
        // if (showAnaesthetistsGMC) {
        //     notificationFields.push(
        //         <Container className={"row mt-2"}>
        //             <Container className={"col-12"}>
        //                 <Text
        //                     name={"notification_anaesthetist_gmc"}
        //                     label={Rosetta.string("registration.notifications_option_anaesthetist_gmc_number")}
        //                     className={"form-control"}
        //                     mandatory={false}/>
        //             </Container>
        //         </Container>
        //     );
        // }
    }

    return (
        <div className={"container registration-screen app-screen"}>
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <ScreenTitle title={Rosetta.string("registration.title")} />
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-lg-8 col-xl-6"}>

                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"alert alert-info"}>
                                <p><strong>{Rosetta.string("registration.explain_title")}</strong></p>
                                <p>{Rosetta.string("registration.explain_message")}</p>
                                <p>
                                    <button className={"btn btn-outline-dark full-width"}
                                            onClick={() => launchPage("page/privacy")}>{Rosetta.string("registration.explain_privacy")}</button>
                                    <button className={"btn btn-outline-dark full-width mt-1"}
                                            onClick={() => launchPage("page/terms")}>{Rosetta.string("registration.explain_terms")}</button>
                                    <button className={"btn btn-outline-dark full-width mt-1"}
                                            onClick={() => launchPage("page/websiteTerms")}>{Rosetta.string("registration.explain_website_terms")}</button>
                                    <button className={"btn btn-outline-dark full-width mt-1"}
                                            onClick={() => launchPage("page/cookies")}>{Rosetta.string("registration.explain_cookie")}</button>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={"file-hide"}>
                    <div>DO NOT REMOVE!! This is here to catch Chrome autofill because Google doesn't honour autofill=off. Thanks, Google.</div>
                        <input type={"text"} id={"username"} name={"username"} />
                        <input type={"password"} id={"password"} name={"password"} />
                    </div>

                    <div className={"row mt-4"}>
                        <div className={"col-12"}>

                            <Former state={formData} callback={formDidCallback}>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <h3>{Rosetta.string("registration.title_account")}</h3>
                                    </div>
                                </div>

                                <Container className={"row mt-2"}>
                                    <Container className={"col-12"}>
                                        <Container className={"card"}>
                                            <Container className={"card-body"}>

                                                {organisationPickElem}

                                                <Container className={"row"}>
                                                    <Container className={"col-12"}>
                                                        <Text
                                                            name={"emailAddress"}
                                                            label={Rosetta.string("registration.user_email")}
                                                            className={"form-control"}
                                                            mandatory={true}/>
                                                    </Container>
                                                </Container>

                                                {emailInvalidElem}

                                                <div className={"row mt-2"}>
                                                    <div className={"col-12"}>
                                                        <label>{Rosetta.string("registration.user_password")}</label>
                                                        <PasswordValidator callback={passwordDidCallback}/>
                                                    </div>
                                                </div>

                                                <Container className={"row mt-2"}>
                                                    <Container className={"col-12"}>
                                                        <Text
                                                            type={"password"}
                                                            name={"passwordRetype"}
                                                            label={Rosetta.string("registration.user_password_retry")}
                                                            className={"form-control"}
                                                            mandatory={true}/>
                                                    </Container>
                                                </Container>

                                                {passwordMismatchElem}

                                            </Container>
                                        </Container>
                                    </Container>
                                </Container>


                                <div className={"row mt-4"}>
                                    <div className={"col-12"}>
                                        <h3>{Rosetta.string("registration.title_personal")}</h3>
                                    </div>
                                </div>

                                <Container className={"row mt-2"}>
                                    <Container className={"col-12"}>
                                        <Container className={"card"}>
                                            <Container className={"card-body"}>

                                                <Container className={"row"}>
                                                    <Container className={"col-12"}>
                                                        <Text
                                                            name={"givenName"}
                                                            label={Rosetta.string("registration.user_given_name")}
                                                            className={"form-control"}
                                                            mandatory={true}/>
                                                    </Container>
                                                </Container>

                                                <Container className={"row mt-2"}>
                                                    <Container className={"col-12"}>
                                                        <Text
                                                            name={"familyName"}
                                                            label={Rosetta.string("registration.user_family_name")}
                                                            className={"form-control"}
                                                            mandatory={true}/>
                                                    </Container>
                                                </Container>

                                                <Container className={"row mt-2"}>
                                                    <Container className={"col-12"}>
                                                        <DropDown
                                                            name={"ageBand"}
                                                            label={Rosetta.string("registration.user_age_band")}
                                                            className={"form-select"}>

                                                            {
                                                                ageBands.map((band) =>
                                                                    <option value={band}>{band}</option>
                                                                )
                                                            }

                                                        </DropDown>
                                                    </Container>
                                                </Container>

                                                <Container className={"row mt-2"}>
                                                    <Container className={"col-12"}>
                                                        <DateTime
                                                            name={"expectedEndDate"}
                                                            label={Rosetta.string("registration.user_expected_date")}
                                                            minDate={Chronos.now().startOfDay().seconds()}
                                                            maxDate={Chronos.now().endOfDay().add(2, Chronos.YEARS).seconds()}
                                                            className={"form-control"}
                                                            mandatory={true}/>

                                                        <div className={"alert alert-info mt-2"}>
                                                            <div>{Rosetta.string("registration.user_expected_date_explain_1")}</div>
                                                            <div>
                                                                <ol>
                                                                    <li>{Rosetta.string("registration.user_expected_date_explain_2")}</li>
                                                                    <li>{Rosetta.string("registration.user_expected_date_explain_3")}</li>
                                                                </ol>
                                                            </div>
                                                            <div>{Rosetta.string("registration.user_expected_date_explain_4")}</div>
                                                        </div>
                                                    </Container>
                                                </Container>

                                                <Container className={"row mt-2"}>
                                                    <Container className={"col-12"}>
                                                        <DropDown
                                                            name={"clinicalRoleId"}
                                                            label={Rosetta.string("registration.user_clinical_role")}
                                                            className={"form-select"}>
                                                            {
                                                                clinicalRoles.map((role) => (
                                                                    <option value={role.id}>{role.name}</option>
                                                                ))
                                                            }
                                                        </DropDown>
                                                    </Container>
                                                </Container>

                                                {gcmNumberElem}

                                                <Container className={"row mt-2"}>
                                                    <Container className={"col-12"}>

                                                        <DropDown
                                                            name={"responsibility"}
                                                            label={Rosetta.string("registration.user_responsibility")}
                                                            className={"form-select"}>

                                                            {
                                                                responsibilities.map((responsibility) =>
                                                                    <option
                                                                        value={responsibility}>{responsibility}</option>
                                                                )
                                                            }

                                                        </DropDown>

                                                    </Container>
                                                </Container>

                                            </Container>
                                        </Container>
                                    </Container>
                                </Container>

                                <div className={"row mt-4"}>
                                    <div className={"col-12"}>
                                        <h3>{Rosetta.string("registration.notifications_title")}</h3>
                                    </div>
                                </div>

                                <Container className={"row mt-2"}>
                                    <Container className={"col-12"}>
                                        <Container className={"card"}>
                                            <Container className={"card-body"}>

                                                <div className={"row"}>
                                                    <div className={"col-12"}>
                                                        <div className={"alert alert-info"}>
                                                            <p>{Rosetta.string("registration.notifications_notice_title")}</p>
                                                            <p>{Rosetta.string("registration.notifications_notice_message_1")}</p>
                                                            <p>{Rosetta.string("registration.notifications_notice_message_2")}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <Container className={"row"}>
                                                    <Container className={"col-12"}>
                                                        <DropDown
                                                            name={"notification_overall"}
                                                            label={Rosetta.string("registration.notifications_option_overall")}
                                                            className={"form-select"}>

                                                            <option value={"1"}>{Rosetta.string("common.yes")}</option>
                                                            <option value={"0"}>{Rosetta.string("common.no")}</option>

                                                        </DropDown>
                                                    </Container>
                                                </Container>

                                                {notificationFields}

                                            </Container>
                                        </Container>
                                    </Container>
                                </Container>

                                {policyElems}

                                <Container className={"row mt-4"}>
                                    <Container className={"col-12 text-center"}>
                                        {submitButton}
                                    </Container>
                                </Container>

                            </Former>

                        </div>
                    </div>

                </div>
            </div>

            <OrganisationPickerModal
                shown={organisationPickerShown}
                callback={organisationPickerDidCallback}/>

        </div>
);

}

/*

    <Container className={"row mt-2"}>
        <Container className={"col-12"}>
            <DateTime
                name={"dateOfBirth"}
                label={Rosetta.string("registration.user_date_of_birth")}
                className={"form-control"}
                mandatory={true}/>
        </Container>
</Container>

<Container className={"row mt-2"}>
    <Container className={"col-12"}>
        <Text
            name={"placeOfWork"}
            label={Rosetta.string("registration.user_place_of_work")}
            className={"form-control"}
            mandatory={false} />
    </Container>
</Container>

<Container className={"row mt-2"}>
    <Container className={"col-12"}>
        <Text
            name={"phoneNumber"}
            label={Rosetta.string("registration.user_phone_number")}
            className={"form-control"}
            mandatory={true} />
    </Container>
</Container>

<Container className={"row mt-2"}>
    <Container className={"col-12"}>
        <Text
            name={"middleName"}
            label={Rosetta.string("registration.user_middle_names")}
            className={"form-control"}
            mandatory={false}/>
    </Container>
</Container>

 */