import axios from 'axios';
import Axios from 'axios';
import Rosetta from "../rosetta/Rosetta";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const API = (function(axios) {
    return {
        setAuthenticationToken : (token) => {
            if (token !== undefined && token !== null) {
                axios.defaults.headers.common["authtoken"] = token;
            } else if (token === null) {
                delete axios.defaults.headers.common["authtoken"];
            }
        },
        setOrganisationId : (organisationId) => {
            if (organisationId) {
                axios.defaults.headers.common["x-organisation-id"] = organisationId;
            }  else {
                delete axios.defaults.headers.common["x-organisation-id"];
            }
        },
        setAuthFailureCallback : (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback();
                    } else {
                        return status;
                    }
                }
            }
        },
        post : (url, data) => new Promise((resolve) => {
            Axios.post(url, data)
                .then((r) => {
                    const resp = API.parse(r);
                    resolve(resp);
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        }),
        get : (url) => new Promise((resolve) => {
            Axios.get(url)
                .then((r) => {
                    const resp = API.parse(r);
                    resolve(resp);
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        }),
        parse : (resp) => {
            let out = {
                success : false,
                data : null,
                error : {
                    desc : Rosetta.string("common.error_common_uncoded"),
                    code : -1
                }
            };

            if (resp !== null) {
                out.success = resp.data.success;
                if (out.success) {
                    if (resp.data.hasOwnProperty("data")) {
                        out.data = resp.data.data;
                    } else {
                        out.data = resp.data;
                    }
                } else {
                    if (resp.data.error !== undefined) {
                        out.error.desc = resp.data.error.desc;
                        out.error.code = resp.data.error.code;
                    }
                }
            }

            return out;
        },
        formatError : (resp) => {
            if (!resp.success) {
                return resp.error.desc + " [" + resp.error.code + "]";
            }
            return "";
        },
        objectToPostData : (object) => {
            let keys = Object.keys(object);
            let formData = new FormData();

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let value = object[key];
                if (typeof value === 'object' && value !== null) {
                    value = JSON.stringify(value);
                }

                formData.append(key, value);
            }

            return formData;
        },
        defaultError : (errorCode) => {
            return Rosetta.string("common.error_common_unknown", { error_code : errorCode });
        },
        ensureUrl : (input) => {
            // if (input.startsWith("http://") || input.startsWith("https://")) {
            //     return input;
            // }

            if (input.startsWith("/")) {
                const baseUrl = process.env.REACT_APP_BASE_URL;
                if (baseUrl.endsWith("/")) {
                    input = input.substring(1);
                    return baseUrl + input;
                }
            }

            return input;
        }
    }
}) (axios);

export const ENDPOINTS = {
    auth : {
        login : "auth/login",
        requestPasswordReset : "auth/requestPasswordReset",
        requestSecureRegistration : "auth/requestSecureRegistration",
        registerSecureLogin : "auth/registerSecureLogin",
        requestSecureLogin : "auth/requestSecureLogin",
        submitSecureLogin : "auth/submitSecureLogin",
        checkPasswordConformity : "auth/checkPasswordConformity",
        getClinicalRoles : "auth/getClinicalRoles",
        submitUserRegistration : "auth/submitUserRegistration",
        getOrganisations : "auth/getOrganisations",
        keepAlive : "keep-alive",
        validatePasswordResetToken : "auth/validatePasswordResetToken",
        submitPasswordReset : "auth/submitPasswordReset"
    },
    user : {
        fetchLocations : "user/fetchLocations",
        getAppNotificationCounts : "user/getAppNotificationCounts",
        updateUserPassword : "user/updateUserPassword",
        registerPushNotificationToken : "user/registerPushNotificationToken",
        getOrganisations : "user/getOrganisations",
        setTwoFactorAuthenticationState : "user/setTwoFactorAuthenticationState",
        disableSecureLogin : "user/disableSecureLogin",
        requestUserDeactivation : "user/requestUserDeactivation",
        setNotificationsEnabled : "user/setNotificationsEnabled",
        getCaseRecordFilters : "user/getCaseRecordFilters",
        submitCaseRecordFilter : "user/submitCaseRecordFilter",
        deleteCaseRecordFilter : "user/deleteCaseRecordFilter",
        getUserNotificationPreferences : "user/getUserNotificationPreferences",
        submitUserNotificationPreferences : "user/submitUserNotificationPreferences"
    },
    organisation : {
        getOrganisationPolicies : "organisation/getOrganisationPolicies",
        getOrganisationAcceptedDomains : "organisation/getOrganisationAcceptedDomains",
        getOrganisationPoliciesRequiringAction : "organisation/getOrganisationPoliciesRequiringAction",
        acceptOrganisationPolicies : "organisation/acceptOrganisationPolicies"
    },
    patient : {
        getPatients : "patient/getPatients"
    },
    caseRecord : {
        getFragments : "caseRecord/getFragments",
        getCaseRecord : "caseRecord/getCaseRecord",
        submitMissingClinician : "caseRecord/submitMissingClinician",
        generateCaseRecordExport : "caseRecord/generateCaseRecordExport",
        handleAction : "caseRecord/handleAction",
        getUserEmailConfig : "caseRecord/getUserEmailConfig",
        getCaseRecordStructure : "caseRecord/getCaseRecordStructure",
        getCaseRecordFilterTypes : "caseRecord/getCaseRecordFilterTypes"
    },
    reflection : {
        fetchAllReflectionsForCurrentUser : "reflection/fetchAllReflectionsForCurrentUser",
        fetchReflection : "reflection/fetchReflection",
        submitReflection : "reflection/submitReflection",
        setReflectionReminder : "reflection/setReflectionReminder",
        updateReflectionReminder : "reflection/updateReflectionReminder",
        getReflectionSuggestionPreferences : "reflection/getReflectionSuggestionPreferences",
        updateReflectionSuggestionPreferences : "reflection/updateReflectionSuggestionPreferences",
        getReflectionInfluences : "reflection/getReflectionInfluences",
        fetchReflectionsForCaseRecord : "reflection/fetchReflectionsForCaseRecord",
        exportReflection : "reflection/exportReflection"
    },
    appConfig : {
        getAppConfig : "appConfig/get"
    },
    analytics : {
        track : "analytics/track"
    }
};
